<template>
  <div class="reset-password">
    <credentials-form
      v-if="currentStep === steps.creds"
      @submit="onLoginEnter"
      @back="$router.push({ name: 'auth' })"
    />

    <enter-code-form
      v-if="currentStep === steps.code"
      v-model="form.security_code"
      :key="steps.code"
      :two-fa-type="authType"
      :error-message="confirmError"
      @submit="onConfirmCodeEnter"
      @back="onGoBack"
    />

    <enter-code-form
      v-if="currentStep === steps.twofa"
      v-model="token_2fa"
      :key="steps.twofa"
      :two-fa-type="authType"
      :error-message="verifyError"
      @submit="on2FaVerify"
      @back="onGoBack"
    >
      Двухфакторная аутентификация
    </enter-code-form>
  </div>
</template>

<script>
import CredentialsForm from '@/components/Recovery/CredentialsForm.vue'
import EnterCodeForm from '@/components/Common/EnterCodeForm.vue'
import { redirectToReturnUrl } from '@/utils/system'

export default {
  components: {
    CredentialsForm,
    EnterCodeForm
  },

  data: () => ({
    steps: {
      creds: 1,
      code: 2,
      twofa: 3
    },
    currentStep: 1,
    form: {
      login: '',
      new_password: '',
      security_code: ''
    },
    authType: '',
    token_2fa: '',
    confirmError: '',
    verifyError: ''
  }),

  methods: {
    onGoNext () {
      this.currentStep++
    },

    onGoBack () {
      this.currentStep--
    },

    onLoginEnter ({ requires2Fa, authType, ...credentials }) {
      this.form = credentials
      this.authType = authType
      if (requires2Fa) {
        this.onGoNext()
      }
      this.onGoNext()
    },

    async onConfirmCodeEnter () {
      this.$metrics.hit(this.$route.meta?.metricsEvents?.enterCode)
      try {
        this.isLoading = true
        this.confirmError = ''

        const { access_token: token } = await this.$authService.sendRecoverConfirmCode(this.form)

        redirectToReturnUrl(token)
      } catch (e) {
        this.confirmError = e.response?.data?.detail
      } finally {
        this.isLoading = false
      }
    },

    async on2FaVerify () {
      try {
        this.isLoading = true
        this.verifyError = ''

        const { access_token: token } = await this.$authService.resetPassword({
          ...this.form,
          token_2fa: this.token_2fa
        })

        redirectToReturnUrl(token)
      } catch (e) {
        this.verifyError = e.response?.data?.detail
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
