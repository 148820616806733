<template>
  <div class="reset-password">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <form
        class="form form_content_auth"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <fieldset class="form-fields-group">
          <legend class="form-fields-group__title">
            Восстановление пароля
          </legend>

          <ul class="form-fields-list">
            <li class="form-fields-list__item-group">
              <login-field
                v-model="login"
              />
            </li>

            <li class="form-fields-list__item">
              <x-form-item
                v-slot="validationData"
                rules="required|min:8"
                name="Пароль"
                label="Задайте новый пароль"
                vid="password"
              >
                <x-input
                  id="password"
                  v-model="password"
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder="Пароль"
                  v-bind="validationData"
                  :append-icon="showPassword ? 'eye-off' : 'eye-on'"
                  @click:append="showPassword = !showPassword"
                />
              </x-form-item>
            </li>

            <li class="form-fields-list__item">
              <x-form-item
                v-slot="validationData"
                rules="required|confirmed:password"
                name="Повторите пароль"
                label="Повторите пароль"
              >
                <x-input
                  id="confirm_password"
                  v-model="confirmPassword"
                  name="confirm_password"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder="Пароль"
                  v-bind="validationData"
                  :append-icon="showPassword ? 'eye-off' : 'eye-on'"
                  @click:append="showPassword = !showPassword"
                />
              </x-form-item>
            </li>

            <li class="form-fields-list__item reset-password__actions">
              <x-btn
                type="submit"
                block
                :loading="isLoading"
              >
                Получить код подтверждения
              </x-btn>
            </li>

            <li class="form-fields-list__item">
              <back-button
                @click="$emit('back')"
              />
            </li>
          </ul>
        </fieldset>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import BackButton from '@/components/Interface/BackButton.vue'
import LoginField from '@/components/Common/LoginField.vue'
import '@/styles/form.styl'

export default {
  components: {
    BackButton,
    LoginField
  },

  data: () => ({
    login: {
      phone: '',
      email: ''
    },
    password: '',
    confirmPassword: '',
    showPassword: '',
    isLoading: false
  }),

  computed: {
    computedNumber () {
      return this.login.phone.replace(/[()-\s]/g, '')
    }
  },

  created () {
    const { email } = this.$route.query
    if (email) {
      this.login.email = email
    }
  },

  methods: {
    async onSubmit () {
      this.$metrics.hit(this.$route.meta?.metricsEvents?.getConfirmCode)
      try {
        this.isLoading = true

        const login = this.computedNumber || this.login.email
        const { status, requires2Fa, auth_type: authType } = await this.$authService.resetPassword({
          login,
          new_password: this.password
        })

        status && this.$toast.success(status)
        this.$emit('submit', {
          login,
          authType,
          new_password: this.password,
          requires2Fa
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .reset-password
    &__actions
      margin-top 20px
</style>
